import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GestureController, LoadingController, ModalController } from '@ionic/angular';
import { PlyrComponent } from 'ngx-plyr';
import { NgxWatermarkOptions } from 'ngx-watermark';
import * as Plyr from 'plyr';
import { HlsjsPlyrDriver } from './hlsjs-plyr-driver.service';
import { ApiService } from '../providers/api.service';

@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.page.html',
  styleUrls: ['./view-video.page.scss'],
})
export class ViewVideoPage implements OnInit {
  @ViewChild('media') media : ElementRef;

  @Input() path: any;
  @Input() public name: any;
  @ViewChild(PlyrComponent)
  plyr: PlyrComponent;
  // or get it from plyrInit event
  public player: Plyr;
  public videoTime: number = 0;
  public videoCurrentTime: number = 0;
  public currentSpeed = 1;

  public isPlaying: boolean = false;
  public isMute: boolean = false;
  public isVisible: boolean = true;
  public timer: any;
  public isPlayed: boolean = false;
  public username: string;

  public videoType: any;
  public videoSources: Plyr.Source[] = [];


  public selectedQuality: any = 720;
  public qualities: any[] = [
    {
      label: '240p',
      value: 240,
    },
    {
      label: '360p',
      value: 360,
    },
    {
      label: '480p',
      value: 480,
    },
    {
      label: '576p',
      value: 576,
    },
    {
      label: '720p',
      value: 720,
    },
    {
      label: '720p',
      value: 720,
    },
    {
      label: '1080p',
      value: 1080,
    },
  ];
  public settings: Plyr.Options = {
    hideControls: true,
    clickToPlay: false,
    //   controls: ['current-time'],
    disableContextMenu: true,
    controls: ['play', 'progress', 'current-time',  'mute','volume'],
    speed: { selected: 1, options: [0.5, 1, 1.5, 2,] },
    ratio: '4:7',
    autoplay: true,
    invertTime: true,
    settings:['quality', 'speed','captions'],
    quality:{ default: 576, options: [ 1080, 720, 480, 240] },
    blankVideo: 'https://cdn.plyr.io/static/blank.mp4',

  };

  public options: NgxWatermarkOptions = {
    text: this.apiService.userProfile.username,
    width: 140,
    height: 140,
    color: '#ff0000',
    alpha: 0.4,
    degree: -45,
    fontSize: '18px',
    textAlign: 'center',
    backgroundRepeat: 'space',
  };

  public loading;
  public hlsDriver: HlsjsPlyrDriver = new HlsjsPlyrDriver(true);
  constructor(
    private gestureCtrl: GestureController,
    public modalController: ModalController,
    public apiService: ApiService,
    public senitizer: DomSanitizer,
    public loadingCtrl : LoadingController
  ) {

    this.username = this.apiService.userProfile.username;
    console.log(this.username);
  }

  ngOnInit() {
    console.log(this.path);

    if (this.path.includes('youtu') || this.path.includes('youtu.be')) {
      this.videoType = 'youtube';
      this.videoSources[0] = {
        src: this.path,
        provider: 'youtube',
      }
    } else if (this.path.includes('vimeo')) {
      this.videoType = 'vimeo';

      this.videoSources[0] = {
        src: this.path,
        provider: 'vimeo',

      }
    } else if (this.path.includes('.mp4')) {
      this.videoType = 'mp4';

      this.videoSources[0] = {
        src: this.path,
        provider: 'html5',

      };
      console.log(this.videoSources);


    } else {
      this.path = 'https://vz-0c739799-811.b-cdn.net/' + this.path + '/play_' + this.apiService.selectedQuality + '.mp4'; /* this.apiService.selectedQuality */
      console.log(this.path);
      this.videoType = 'mp4';
      this.videoSources[0] = {

        src: this.path,
        provider: 'html5',
      }
    }
    this.loadingCtrl.create({
      message: 'Please wait...',
      spinner: 'lines',
    }).then((res) => {
      res.present();
    }
    );

  }


async  ionViewDidEnter() {
    const gesture = this.gestureCtrl.create({
      gestureName: 'drag-image',
      el: this.media.nativeElement,
      threshold: 70,
      direction: 'y',
      onStart: () => {
        console.log('modal closed!');
        this.close();
      },
    });
  
    gesture.enable();

  

  }

 async error(event: Plyr.PlyrEvent) {
  this.path = 'https://vz-0c739799-811.b-cdn.net/' + this.path + '/play_' + this.apiService.selectedQuality + '.mp4'; /* this.apiService.selectedQuality */
  this.player.play();
    console.log('error', event);
    // alert('Something went wrong with video!');
    this.loadingCtrl.getTop().then(hasLoading => {
      if (hasLoading) {
        this.loadingCtrl.dismiss();
      }
    });

  }

  close() {
    this.modalController.dismiss();
  }


  changeQuality() {
    // this.player.source.sources[0].size = this.selectedQuality;
    // this.videoSources[0].size = this.selectedQuality;
    console.log(this.player);
  }


  timeUpdated(event: Plyr.PlyrEvent) {
    // to update time constantly in ion range
    this.videoCurrentTime = event.detail.plyr.currentTime;
    this.videoTime = this.player.duration;

  }
 async videoInitDone() {
    console.log('videoInitDone');
    this.setVisibility();

  }

  async loadedData(event:any){
    this.loadingCtrl.getTop().then(hasLoading => {
      if (hasLoading) {
        this.loadingCtrl.dismiss();
      }
    });
  }

  async played(event: Plyr.PlyrEvent) {

    // this.hlsDriver.load(this.videoSources[0].src);
    // to get video time 
    this.isPlaying = true;
    this.player.muted ? this.isMute = true : this.isMute = false;
    this.videoTime = this.player.duration;

    this.player.on('error', (event) => {
      console.log('error playing video');
    });

    //! if video not available in current quality
    if (this.player.duration == 0) {
      if (this.path.includes('.mp4')) {
        // this.player.stop();
        this.player.source = {
          type: 'video',
          sources: [
            {
              src: this.path.replace(this.path.includes('720p')?'720p':'480p',this.path.includes('720p')?'480p':'240p'),
              provider: 'html5',
            },
          ]
        };
      }
      console.log('changed url for video:', this.path.replace(this.path.includes('720p')?'720p':'480p',this.path.includes('720p')?'480p':'240p'));


    }
  }

  ended() {
    this.isPlaying = false;
    // this.isVisible = true;
  }

  play(): void {
    this.isPlaying = true;
    this.player.play(); // or this.plyr.player.play()
    this.setVisibility();

  }
  seekingStart(event) {
    this.videoCurrentTime = event.detail.value;
    this.player.currentTime = event.detail.value;
    this.player.pause();

  }
  seeked(event) {

    this.videoCurrentTime = event.detail.value;
    this.player.currentTime = event.detail.value;
    this.player.play();
    /*  console.log("Seeked:", event.detail.plyr.currentTime);
     this.videoCurrentTime = event.detail.plyr.currentTime;
     this.player.currentTime = this.videoCurrentTime;
     this.player.play(); */
    // this.player.currentTime = event.timeStamp;
    this.setVisibility();

  }

  pause(): void {
    this.isPlaying = false;

    this.player.pause(); // or this.plyr.player.play()
    this.setVisibility();

  }

  mute(): void {
    this.isMute = !this.isMute;
    this.player.muted = this.isMute;
    this.setVisibility();

  }

  stop(): void {
    this.isPlaying = false;

    this.player.stop();
    this.setVisibility();
    // or this.plyr.player.stop()
  }


  setVisibility() {
    if (this.videoType != 'html5') {
      //  console.log('setVisibility');
      this.player.toggleControls(this.isVisible = !this.isVisible);
    }

    
    /*     clearTimeout(this.timer);
        this.isVisible = true;
      this.timer =  setTimeout(() => {
          this.isVisible = false
        }, 6000); */
  }

  toggleSpeed(){
   if(this.currentSpeed == 1) {
    this.currentSpeed = 1.5;
    this.player.speed = this.currentSpeed;
   }else if(this.currentSpeed == 1.5) {
    this.currentSpeed = 2;
    this.player.speed = this.currentSpeed;
   }else if(this.currentSpeed == 2) {
    this.currentSpeed = 0.5;
    this.player.speed = this.currentSpeed;
   }else{
    this.currentSpeed = 1;
    this.player.speed = this.currentSpeed;
   }
  }
}
